import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/gallery/graingertnhistory",
  "project": "Grainger Co. Historic Society",
  "projectID": "graingertnhistory",
  "projectURL": "http://graingertnhistory.com",
  "projectDate": "2020-03-21"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const GalleryImg = makeShortcode("GalleryImg");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, nesciunt illum quos id quo repellat dolorum totam sed voluptatem, numquam harum accusamus delectus, soluta laborum? Minima libero atque rerum deserunt? */
    }
    <Gallery mdxType="Gallery">
  <GalleryImg src="graingertnhistory_home.png" alt="Home page screenshot" title="Home" mdxType="GalleryImg" />
  <GalleryImg src="graingertnhistory_stories.png" alt="Stories page screenshot" title="Stories" mdxType="GalleryImg" />
  <GalleryImg src="graingertnhistory_story.png" alt="Story post screenshot" title="Story post" mdxType="GalleryImg" />
  <GalleryImg src="graingertnhistory_story_blocks.png" alt="Story blocks screenshot" title="Story blocks" mdxType="GalleryImg" />
  <GalleryImg src="graingertnhistory_membership.png" alt="Membership page screenshot" title="Membership" mdxType="GalleryImg" />
  <GalleryImg src="graingertnhistory_contact.png" alt="Contact page screenshot" title="Contact" mdxType="GalleryImg" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      